import "core-js/modules/es.function.name.js";
import InPageNav from '@/components/in-page-nav/InPageNav.vue';
import InPageNavBottom from '@/components/in-page-nav/InPageNavBottom.vue';
export default defineComponent({
  name: 'BillingNav',
  components: {
    InPageNav: InPageNav,
    InPageNavBottom: InPageNavBottom
  },
  props: {
    bottom: Boolean
  },
  setup: function setup() {
    var _storeToRefs = storeToRefs(useUserStore()),
      attributes = _storeToRefs.attributes,
      isBusinessMember = _storeToRefs.isBusinessMember;
    return {
      attributes: attributes,
      isBusinessMember: isBusinessMember
    };
  },
  data: function data() {
    return {};
  },
  computed: {
    navArray: function navArray() {
      var showBillingSettingsNav = this.attributes.stripe_subscription_id && this.attributes.stripe_subscription_id.length && !this.isBusinessMember;
      var nav = [{
        routeName: 'billing',
        displayTitle: this.$t('billing.navigation.plan_details'),
        icon: '$vuetify.icons.setup_money',
        cypressIdentifier: 'planButton',
        showAsActive: this.$route.name === 'billing-settings' && !showBillingSettingsNav
      }];

      // Add link to usage page for users that are not a member of a business
      if (!this.isBusinessMember) {
        nav.push({
          routeName: 'billing-usage',
          displayTitle: this.$t('billing.navigation.costs'),
          icon: '$vuetify.icons.f_dashboard',
          cypressIdentifier: 'usageButton'
        });
      }

      // Add the billing settings navigation link when the user is in the fair flat plan
      if (showBillingSettingsNav) {
        nav.push({
          routeName: 'billing-settings',
          displayTitle: this.$t('billing.navigation.payment_details'),
          icon: '$vuetify.icons.wallet',
          cypressIdentifier: 'settingsButton'
        });
      }
      return nav;
    }
  }
});