import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.starts-with.js";
import BannerPaymentFailed from '@/components/banners/BannerPaymentFailed.vue';
import Heading from '@/components/Heading.vue';
import BillingNav from '@/components/in-page-nav/BillingNav.vue';
import BusinessBillingNav from '@/components/in-page-nav/BusinessBillingNav.vue';
import BusinessMembersNav from '@/components/in-page-nav/BusinessMembersNav.vue';
import BusinessProfileNav from '@/components/in-page-nav/BusinessProfileNav.vue';
import ProfileNav from '@/components/in-page-nav/ProfileNav.vue';
import WidgetTrialExpiration from '@/components/widgets/WidgetTrialExpiration.vue';
import { useBusinessStore } from '@/stores/business';
import { useUserStore } from '@/stores/user';
export default defineComponent({
  name: 'Layout2',
  components: {
    BannerPaymentFailed: BannerPaymentFailed,
    Heading: Heading,
    BillingNav: BillingNav,
    BusinessBillingNav: BusinessBillingNav,
    BusinessMembersNav: BusinessMembersNav,
    BusinessProfileNav: BusinessProfileNav,
    ProfileNav: ProfileNav,
    WidgetTrialExpiration: WidgetTrialExpiration
  },
  props: {
    name: String,
    subtitle: String,
    title: String,
    titleDetail: String,
    paddedHeading: Boolean,
    headingDataCy: String,
    inPageNav: String,
    wide: Boolean
  },
  setup: function setup() {
    var businessStore = useBusinessStore();
    var userStore = useUserStore();
    return {
      businessStore: businessStore,
      userStore: userStore
    };
  },
  computed: {
    showPaymentIssuesBanner: function showPaymentIssuesBanner() {
      // to make sure to only display it on the /profile pages
      return !!this.$route.name && this.$route.name.startsWith('profile') && this.businessStore.hasPaymentIssues && this.userStore.isBusinessAdmin;
    }
  }
});